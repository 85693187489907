
import classes from '../../assets/style/product.module.scss'
import {productList} from '../../config/data'

const Product1 = () => {
    const product = productList[1];

	return (
		<div className="white-container">
            <div className="banner">
                <img src={require("../../assets/imgs/"+ product.banner)} alt="" />
            </div>
            <div className="wrap-1200">
                <div className="c_title e_center">
                    <div className="text">产品特点</div>
                    <div className="sub-title">Product Features</div>
                </div>
                {product.features.map((item,index) => 
                    <div className={classes.list4_item + ' l_col'} key={item.icon}>
                        <div className={classes.list4_icon}><img src={require("../../assets/imgs/"+item.icon)} alt={item.title} style={item.style}/></div>
                        <div className="full">
                            <div className={classes.list4_title}>
                                <div className={classes.list4_text}>{item.title}</div>
                                <div className={classes.list4_no}>0{index+1}</div>
                            </div>
                            {item.content.map(sub =>
                                <div className={classes.list4_content} key={sub.content}>
                                    {sub.title && <div className={classes.list4_sub_title}>{sub.title}</div>}
                                    <div className={classes.list4_desc} dangerouslySetInnerHTML={{__html:sub.content}}></div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
		</div>
	)
}
export default Product1;
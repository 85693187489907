import {useParams} from 'react-router-dom'
import Product1 from './products/Product1'
import Product2 from './products/Product2'
import Product3 from './products/Product3'
import Product4 from './products/Product4'
import Product5 from './products/Product5'

export default function Product() {
	const {id} = useParams()
	return (
		<div className="container">
            {id==='1' && <Product1/>}
			{id==='2' && <Product2/>}
			{id==='3' && <Product3/>}
			{id==='4' && <Product4/>}
			{id==='5' && <Product5/>}
		</div>
	)
}

import classes from '../../assets/style/footer.module.scss';
import { NavLink} from 'react-router-dom'
import global from '../../config/config'

const Header = (props) => {
    function computedClassName({isActive}){
        return classes.nav_item +' '+ (isActive ? classes.active : '');
    }
    return (
        <footer className={classes.footer}>
            <div className={classes.footer_col + ' l_col'}>
                <div className="full">
                    <div className={classes.nav}>
                        <NavLink to="index" className={computedClassName}>首页</NavLink>
                        <NavLink to="product" className={computedClassName}>产品中心</NavLink>
                        <NavLink to="news" className={computedClassName}>新闻中心</NavLink>
                        <NavLink to="about" className={computedClassName}>关于我们</NavLink>
                    </div>
                    <div className={classes.desc + ' l_col'}>
                        <div className={classes.desc_left}>{global.companyName}</div>
                        <div className="full">地址：{global.companyAddress}</div>
                    </div>
                    <div className={classes.desc + ' l_col'}>
                        <div className={classes.desc_left}  dangerouslySetInnerHTML={{__html:global.copyright}}></div>
                        <div className="full"><a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">{global.ICP}</a></div>
                    </div>
                </div>
                <div className={classes.side}>联系电话：{global.contactPhone}</div>
            </div>
        </footer>
    )
}

export default Header;
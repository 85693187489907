import { useRoutes } from 'react-router-dom'
import{useEffect} from 'react'
import routes from './routes'
import Header from './components/frame/Header'
import Footer from './components/frame/Footer'

const App = () => {
    const elements = useRoutes(routes);

    useEffect(() => {
        document.getElementById('root').scrollTop = 0;
    })
    

    return (
        <div className="main-container">
            <Header />
            {elements}
            <Footer />
        </div>
    );
}

export default App;

import classes from '../assets/style/about.module.scss'

export default function About() {
    
	return (
		<div className="container white-container">
			<div className="banner">
                <img src={require("../assets/imgs/banner-about.png")} alt="" />
            </div>
            {/* <div className={classes.title}>
                <div className={classes.title_text}>
                    关于我们
                </div>
            </div>
            <div className={classes.content}>艾拓体育（aituo）是国内专业的智慧体育服务商，<br/>以科技为体育赋能与千万家体育机构共同进步</div> */}
            <img src={require("../assets/imgs/about1.png")} alt="" className={classes.content_image} />
            <img src={require("../assets/imgs/about2.png")} alt="" className={classes.content_image} />
            <img src={require("../assets/imgs/about3.png")} alt="" className={classes.content_image} />
		</div>
	)
}

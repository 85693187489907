import classes from '../../assets/style/news.module.scss'

const News1 = () => {
    const imageList1 = [
        { image: '1-3.png' },
        { image: '1-4.png' },
        { image: '1-5.png' },
        { image: '1-6.png' },
        { image: '1-7.png' }
    ]
    const imageList2 = [
        { image: '1-8.png', title: '参展产品在个旧市红旗小学现场' },
        { image: '1-9.png', title: '参展产品在个旧市红旗小学现场' }
    ]
    return (
        <div>
            <div className="breadcrumb-sub">
                <div className="wrap-1200">
                    您的位置：首页 &gt; 新闻中心
                </div>
            </div>
            <div className="white-container">
                <div className="wrap-1200">
                    <div className={classes.title}>
                        <div className={classes.title_text}>
                            智赋校园 慧育未来——云南移动发布数字校园平台
                        </div>
                        <div className={classes.title_info}>
                            05-19
                        </div>
                    </div>
                    <div className={classes.content}>
                        <p>5月19日，中国移动云南公司(以下简称“云南移动”)数字校园平台发布会在红河州蒙自市举行，红河、昭通、曲靖、文山、保山等15州市教育体育局以及个旧市人民政府、中国移动集团公司、卓望信息技术(北京)有限公司、中移(成都)通信信息技术有限公司、华为技术有限公司等单位领导以及来自数字校园行业领域诸多合作伙伴等300余名嘉宾参加发布会。</p>
                        <p>会上，云南移动发布了数字校园平台及《数字校园产品白皮书》。数字校园平台是云南移动基于移动云全新打造的安全、智能、快捷的区校一体化云平台，面向省、市、区县教育体育局提供区域大数据分析、视频监控级联、安全围栏等服务，面向学校、师生提供教育教学、校园安全、校园生活、校园办公四大场景应用。平台具备生态整合能力，实现应用、硬件集成，能够有效提供一站式校园信息化解决方案。《云南移动数字校园产品白皮书》贴合数字校园建设标准，围绕基础网络、数字终端、教育教学应用、标准直录播教室、标准数字教室、其他配套服务六大要点提供解决方案。</p>
                        <div className={classes.image}>
                            <img src={require(`../../assets/imgs/news/1-1.png`)} alt="" />
                            <p className={classes.image_info}>云南移动数字校园平台发布</p>
                        </div>
                        <div className={classes.image}>
                            <img src={require(`../../assets/imgs/news/1-2.png`)} alt="" />
                            <p className={classes.image_info}>《云南移动数字校园产品白皮书》发布</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`wrap-1200 ${classes.content}`}>
                <p>长沙数物信息应云南移动的邀请，参与“体育教学”,在发布会的展厅展出“蓝牙智能计数跳绳”、”团体跳绳训练”、“大课间跳绳”、“跳绳投屏”、“跳绳考试一体机”五个产品</p>
                <div className="l_col">
                    {imageList1.map(item =>
                        <div className="full" key={item.image}>
                            <img src={require(`../../assets/imgs/news/${item.image}`)} alt="" className={classes.col_image1} />
                        </div>
                    )}
                </div>
            </div>
            <div className="white-container">
                <div className={`wrap-1200 ${classes.content}`}>
                    <p>长沙数物信息科技有限公司产品经理“阳益群”在学校现场对参展的各区县的教育局、学校的工作人员进行详细的讲解。</p>
                    <div className="l_col">
                        {imageList2.map(item =>
                            <div className="full" key={item.image}>
                                <img src={require(`../../assets/imgs/news/${item.image}`)} alt="" className={classes.col_image2} />
                                <p className={classes.image_info}>{item.title}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default News1;
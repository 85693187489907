import {useParams} from 'react-router-dom'
import News1 from './news/News1'

export default function Product() {
	const {id} = useParams()
	return (
		<div className="container">
            {id==='1' && <News1/>}
		</div>
	)
}

import { NavLink } from 'react-router-dom'
import classes from '../assets/style/news.module.scss'

export default function News() {
	return (
		<div className="container white-container">
			<div className="banner">
				<img src={require(`../assets/imgs/banner-news.png`)} alt="" />
			</div>

			<div className="wrap-1200">
				<div className="breadcrumb">
					您的位置：首页 &gt; 新闻中心
				</div>
				<div className={`l_col ${classes.news_list}`}>
					<div className={classes.news_list_image}>
						<img src={require(`../assets/imgs/news/1.png`)} alt="" />
					</div>
					<div className={classes.news_list_main}>
						<div className={`l_col ${classes.news_list_title}`}>
							<div className="full">智赋校园 慧育未来——云南移动发布数字校园平台</div>
						</div>
						<div className={classes.news_list_content}>
							5月19日，中国移动云南公司(以下简称“云南移动”)数字校园平台发布会在红河州蒙自市举行，红河、昭通、曲靖、文山、保山等15州市教育体育局以及个旧市人民政府、中国移动集团公司、卓望信息技术(北京)有限公司、中移(成都)通信信息技术有限公司、华为技术有限公司等单位领导以及来自数字校园行业领域诸多合作伙伴等300余名嘉宾参加发布会。
						</div>
						<div className={classes.news_list_more}>
							<NavLink to="/news/1">查看详情 &gt;&gt;</NavLink>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

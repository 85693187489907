
import classes from '../../assets/style/product.module.scss'
import {productList} from '../../config/data'

const Product1 = () => {
    const product = productList[4];

	return (
		<div className="white-container">
            <div className="banner">
                <img src={require(`../../assets/imgs/${product.banner}`)} alt="" />
            </div>
            <div className="wrap-1200">
                <div className="c_title e_center">
                    <div className="text">产品特点</div>
                    <div className="sub-title">Product Features</div>
                </div>
                <div className={`${classes.list1} l_col`}>
                    {product.features.map(item => 
                        <div className={`${classes.list1_item} l_col`} key={item.icon}>
                            <div className={classes.list1_icon}><img src={require(`../../assets/imgs/${item.icon}`)} alt={item.title}/></div>
                            <div className="full">
                                <div className={classes.list1_title}>{item.title}</div>
                                <div className={classes.list1_desc} dangerouslySetInnerHTML={{__html:item.content}}></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className="c_title e_center">
                    <div className="text">应用场景</div>
                    <div className="sub-title">Application Scenario</div>
                </div>
                <div className={`${classes.list3} l_col`}>
                    {product.application.map(item => 
                        <div className={`${classes.list3_item} l_col`} key={item.image}>
                            <div className={classes.list3_image}><img src={require(`../../assets/imgs/${item.image}`)} alt={item.title}/></div>
                            <div className="full">
                                <div className={classes.list3_title}>{item.title}</div>
                                <div className={classes.list3_content} dangerouslySetInnerHTML={{__html:item.content}}></div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
		</div>
	)
}
export default Product1;
import {Link} from 'react-router-dom'
import classes from '../assets/style/index.module.scss';

export default function Index() {
    
	return (
		<div className="container">
			<div className="banner">
                <img src={require("../assets/imgs/banner-index.png")} alt=""/>
            </div>
            <div className="wrap">
                <div className={classes.nav1 + ' l_col l_col-space-3'}>
                    <div className="full l_col">
                        <div className="icon"><img src={require("../assets/imgs/index-nav-1.png")} alt="" /></div>
                        <div className="full">
                            <div className={classes.nav1_title}>专注跳绳训练产品</div>
                            <div className={classes.nav1_desc}>多年专注跳绳团队训练产品研发</div>
                        </div>
                    </div>
                    <div className="full l_col">
                        <div className="icon"><img src={require("../assets/imgs/index-nav-2.png")} alt="" /></div>
                        <div className="full">
                            <div className={classes.nav1_title}>生产制造一体化</div>
                            <div className={classes.nav1_desc}>智能跳绳、工控机、主机平板</div>
                        </div>
                    </div>
                    <div className="full l_col">
                        <div className="icon"><img src={require("../assets/imgs/index-nav-3.png")} alt="" /></div>
                        <div className="full">
                            <div className={classes.nav1_title}>智慧跳绳解决方案</div>
                            <div className={classes.nav1_desc}>智慧跳绳数字化服务商、B端跳绳Saas系统</div>
                        </div>
                    </div>
                </div>
                <div className={classes.nav2}>
                    <div className="l_col">
                        <div className={classes.nav2_item1}>
                            <img src={require("../assets/imgs/index-nav2-bg1.png")} className={classes.nav2_image} alt=""/>
                            <div className={classes.nav2_title}>蓝牙智能计数跳绳</div>
                            <div className={classes.nav2_desc}>由蓝牙智能跳绳和小程序组成，智能跳绳具备自由跳，倒计时，倒计数，团队四种模式。小程序包含运动达人和家长模式</div>
                            <Link className={classes.nav2_more1} to="/product/1">查看详情</Link>
                        </div>
                        <div>
                            <div className={classes.nav2_item2}>
                                <img src={require("../assets/imgs/index-nav2-bg2.png")} className={classes.nav2_image} alt=""/>
                                <div className={classes.nav2_title}>团体跳绳训练</div>
                                <div className={classes.nav2_desc}>由蓝牙智能跳绳、跳绳工控机和小程序组成。小程序包含随堂训练和跳绳测试二种模式</div>
                                <Link className={classes.nav2_more2} to="/product/3">查看详情</Link>
                            </div>
                            <div className={classes.nav2_item2}>
                                <img src={require("../assets/imgs/index-nav2-bg3.png")} className={classes.nav2_image} alt=""/>
                                <div className={classes.nav2_title}>大课间跳绳</div>
                                <div className={classes.nav2_desc}>由智能工控机和小程序组成，模拟广播体操的组织流程，让大课间锻炼变的丰富多彩。</div>
                                <Link className={classes.nav2_more2} to="/product/4">查看详情</Link>
                            </div>
                        </div>
                    </div>
                    <div className="l_col">
                        <div className={classes.nav2_item3}>
                            <img src={require("../assets/imgs/index-nav2-bg4.png")} className={classes.nav2_image} alt=""/>
                            <div className={classes.nav2_title}>跳绳投屏</div>
                            <div className={classes.nav2_desc}>由智能工控机和控制程序组成，和一起跳产品、考试一体机配合使用，输出hdmi高清投屏画面，满足正式活动的大屏数据展现</div>
                            <Link className={classes.nav2_more1} to="/product/5">查看详情</Link>
                        </div>
                        <div className={classes.nav2_item3}>
                            <img src={require("../assets/imgs/index-nav2-bg5.png")} className={classes.nav2_image} alt=""/>
                            <div className={classes.nav2_title}>跳绳考试一体机</div>
                            <div className={classes.nav2_desc}>由智能工控机和PC界面组成，搭建体育中考的考前组织和中考考试的场景。</div>
                            <Link className={classes.nav2_more1} to="/product/2">查看详情</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="white-container">
                <div className={classes.nav3_box + ' wrap'}>
                    <div className="c_title">
                        <div className="text">应用场景</div>
                        <div className="sub-title">Application scenarios</div>
                    </div>
                    <div className={classes.nav3}>
                        <div className={classes.nav3_item}>
                            <div className={classes.nav3_image}>
                                <img src={require("../assets/imgs/t1.png")} alt="" />
                            </div>
                            <div className={classes.nav3_title}>个人跳绳</div>
                            <div className={classes.nav3_desc}>使用微信小程序完成课后作业打卡，课后练习</div>
                            <div className={classes.nav3_no}>01</div>
                        </div>
                        <div className={classes.nav3_item}>
                            <div className={classes.nav3_image}>
                                <img src={require("../assets/imgs/t2.png")} alt=""/>
                            </div>
                            <div className={classes.nav3_title}>随堂训练</div>
                            <div className={classes.nav3_desc}>体育课堂可快速组织一次性的班级训练</div>
                            <div className={classes.nav3_no}>02</div>
                        </div>
                        <div className={classes.nav3_item}>
                            <div className={classes.nav3_image}>
                                <img src={require("../assets/imgs/t3.png")} alt=""/>
                            </div>
                            <div className={classes.nav3_title}>学校跳绳测试</div>
                            <div className={classes.nav3_desc}>一套设备一天可完成3000人跳绳测试</div>
                            <div className={classes.nav3_no}>03</div>
                        </div>
                    </div>
                    <div className={classes.nav3}>
                        <div className={classes.nav3_item}>
                            <div className={classes.nav3_image}>
                                <img src={require("../assets/imgs/t4.png")} alt=""/>
                            </div>
                            <div className={classes.nav3_title}>赛事投屏</div>
                            <div className={classes.nav3_desc}>自带投屏机，实现远近距离投屏</div>
                            <div className={classes.nav3_no}>04</div>
                        </div>
                        <div className={classes.nav3_item}>
                            <div className={classes.nav3_image}>
                                <img src={require("../assets/imgs/t5.png")} alt=""/>
                            </div>
                            <div className={classes.nav3_title}>大课间跳绳</div>
                            <div className={classes.nav3_desc}>大课间AI跳绳，智能主机自动定时组织</div>
                            <div className={classes.nav3_no}>05</div>
                        </div>
                        <div className={classes.nav3_item}>
                            <div className={classes.nav3_image}>
                                <img src={require("../assets/imgs/t6.png")} alt=""/>
                            </div>
                            <div className={classes.nav3_title}>中考跳绳</div>
                            <div className={classes.nav3_desc}>工控平板一体化设备，离线进行跳绳考试，数据安全可靠</div>
                            <div className={classes.nav3_no}>06</div>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/style/main.scss';
import {BrowserRouter} from 'react-router-dom'
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter><App/></BrowserRouter>
  </React.StrictMode>
);

const remSize = () => {
    const deviceWidth = document.documentElement.clientWidth || window.innerWidth;
    document.documentElement.style.fontSize = deviceWidth / 1920 + "px";
};

remSize();

window.onresize = () => {
    remSize();
};
import {Navigate} from 'react-router-dom'

import Index from '../pages/Index'
import About from '../pages/About'
import Product from '../pages/Product'
import News from '../pages/News'
import NewsDetails from '../pages/NewsDetails'

const routers = [
    {
        path:'/index', 
        element: <Index/>
    },{
        path:'/about', 
        element: <About/>
    },{
        path:'/product/:id', 
        element: <Product/>
    },{
        path:'/product', 
        element: <Navigate to="/product/1"/>
    },{
        path:'/news', 
        element: <News />
    },{
        path:'/news/:id', 
        element: <NewsDetails/>
    },{
        path:'/', 
        element: <Navigate to="/index"/>
    },{
        path:'*', 
        element: <Navigate to="/index"/>
    }
]

export default routers;
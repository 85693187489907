import { NavLink} from 'react-router-dom'
import {useState} from "react";
import classes from '../../assets/style/header.module.scss';
import logo from '../../assets/imgs/logo.png'
import qrcode from '../../assets/imgs/qrcode.png'
import qrcodeWx from '../../assets/imgs/qrcode-wx.png'
import {productList} from '../../config/data'
import global from '../../config/config'

const Header = () => {

    const [showProduct, setShowProduct] = useState(false);

    const computedClassName = ({isActive}) => {
        return classes.nav_item +' '+ (isActive ? classes.active : '');
    }

    return (
        <header className={classes.header}>
            <div className={classes.header_col + ' l_col'}>
                <div className="full">
                    <img src={logo} className={classes.logo} alt={global.name}></img>
                </div>
                <div className="l_col l_col-space-4">
                    <div>
                        <NavLink to="index" className={computedClassName}>首页</NavLink>
                    </div>
                    <div className={classes.float_nav} onMouseEnter={()=>setShowProduct(true)} onMouseLeave={()=>setShowProduct(false)}>
                        <NavLink to="product" className={computedClassName}>产品中心</NavLink>
                        <div className={classes.product_box} style={{display:showProduct?'block':'none'}}>
                            {productList.map(item => 
                                <NavLink to={'/product/' + item.id} className={classes.product_item} key={item.id}>{item.name}</NavLink>
                            )}
                        </div>
                    </div>
                    <div>
                        <NavLink to="news" className={computedClassName}>新闻中心</NavLink>
                    </div>
                    <div>
                        <NavLink to="about" className={computedClassName}>关于我们</NavLink>
                    </div>
                </div>
                <div className={classes.side + ' e_orange l_col'}>
                    联系电话：{global.contactPhone}
                    <div className={classes.qrcodeImage}>
                        <img src={qrcode} alt="微信联系"></img>
                        <div className={classes.qrcodeBox}>
                            <img src={qrcodeWx} alt="微信联系"></img>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
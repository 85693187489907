export const productList = [
    {
        name:'蓝牙智能计数跳绳', 
        id: 1,
        banner:'banner-product1.png',
        features:[
            {
                title:'离线模式',
                content:'外面跳，家里提交作业<br/>家里跳，实时提交作业',
                icon: 'icon-p1.png'
            },{
                title:'绳体复合pvc',
                content:'打脚不痛，不缠绕',
                icon: 'icon-p2.png'
            },{
                title:'长续航',
                content:'330ma大容量电池，充电一次<br/>连续使用60小时',
                icon: 'icon-p3.png'
            },{
                title:'一根绳',
                content:'家庭作业和团队，比赛<br/>考试一根绳',
                icon: 'icon-p4.png'
            },{
                title:'霍尔电磁传感器',
                content:'采用霍尔电磁传感器精准计数',
                icon: 'icon-p5.png'
            }
        ],
        application:[
            {
                image: 't7.png',
                title: '日常训练'
            },{
                image: 't2.png',
                title: '家庭作业'
            },{
                image: 't3.png',
                title: '配合跳绳工控机完成随堂训练'
            }
        ]
    },
    {
        name:'跳绳考试一体机', 
        id: 2,
        banner:'banner-product2.png',
        features:[
            {
                title:'稳定性',
                content:[
                    {
                        title:'考试全局限网',
                        content:'考试全局域网闭环组织，减少外部干扰'
                    },
                    {
                        title:'设备集成功能',
                        content:'一个工控平板集成所有的考试功能，减少设备故障点'
                    }
                ],
                icon: 't12.png',
                style: {width:'530rem',height:'530rem'}
            },{
                title:'安全性',
                content:[
                    {
                        content:'操作全轨迹记录，数据不能更改，导出成绩进行账号管控'
                    }
                ],
                icon: 't13.png',
                style: {width:'700rem',height:'440rem', marginRight:'90rem'}
            },{
                title:'简便性',
                content:[
                    {
                        title:'脚本化管理',
                        content:'考试策划数据同步自动化脚本插件化管理，导出成绩<br/>脚本化管理'
                    },{
                        title:'学习成本低',
                        content:'考试策略在策划时固定，监考老师只做执行，考试执<br/>行界面只有一个按钮，学习成本低'
                    }
                ],
                icon: 't14.png',
                style: {width:'700rem',height:'500rem'}
            },{
                title:'外设丰富性',
                content:[
                    {
                        content:'扫码枪，打印机输入，输出设备全集成，满足考试所有的能力'
                    }
                ],
                icon: 't15.png',
                style: {width:'500rem',height:'500rem', marginRight:'300rem'}
            }
        ]
    },
    {
        name:'团体跳绳训练', 
        id: 3,
        banner:'banner-product3.png',
        features:[
            {
                title:'多人训练',
                content:'工控机能同时组织60个人<br/>进行训练',
                icon: 'icon-p8.png'
            },{
                title:'超广覆盖面积',
                content:'覆盖面积超过2200平方米',
                icon: 'icon-p9.png'
            },{
                title:'长续航',
                content:'自带电池能使用8小时以上',
                icon: 'icon-p3.png'
            },{
                title:'灵活组织',
                content:'自带跳绳和学校统一套装跳绳<br/>二种方式，灵活的组织比赛能力',
                icon: 'icon-p10.png'
            },{
                title:'离线模式',
                content:'不依懒于外网能力，组网方便',
                icon: 'icon-p1.png'
            }
        ],
        application:[
            {
                image: 't7.png',
                title: '随堂训练'
            },{
                image: 't2.png',
                title: '跳绳体测'
            },{
                image: 't3.png',
                title: '比赛'
            }
        ]
    },
    {
        name:'大课间跳绳', 
        id: 4,
        banner:'banner-product4.png',
        features:[
            {
                title:'大吞吐',
                content:[
                    {
                        content:'可以支持1000人以内的同时在一个操场上跳绳，<br/>具备很大的吞吐量'
                    }
                ],
                icon: 't16.png',
                style: {width:'759rem',height:'510rem'}
            },{
                title:'智能化',
                content:[
                    {
                        content:'可以设定策略定时来组织，能让跳绳娱乐性形成机制'
                    }
                ],
                icon: 't17.png',
                style: {width:'550rem',height:'550rem', marginRight:'200rem'}
            },{
                title:'参与感',
                content:[
                    {
                        content:'数据会进行分析，形成榜单，汇总的数据在大屏上展示，<br/>形成一个激励系统，促使大家去跳绳'
                    }
                ],
                icon: 't18.png',
                style: {width:'720rem',height:'489rem'}
            }
        ],
        application:[
            {
                image: 't19.png',
                title: '大课间'
            },{
                image: 't2.png',
                title: '运动一角'
            }
        ]
    },
    {
        name:'跳绳投屏', 
        id: 5,
        banner:'banner-product5.png',
        features:[
            {
                title:'离线模式',
                content:'外面跳，家里提交作业<br/>家里跳，实时提交作业',
                icon: 'icon-p1.png'
            },{
                title:'大屏显示',
                content:'大屏的界面有欢迎页、准备页、<br/>运动页、成绩页，显示大气、严肃',
                icon: 'icon-p6.png'
            },{
                title:'深度融和',
                content:'与一起跳产品和考试一体机深度融和',
                icon: 'icon-p7.png'
            }
        ],
        application:[
            {
                image: 't8.png',
                title: '随堂训练',
                content: '针对跳绳项目构建的体育老师组织<br/>学生进行跳绳的训练，监督训练过程'
            },{
                image: 't9.png',
                title: '跳绳体测',
                content: '使用微信小程序发起跳绳体测'
            },{
                image: 't10.png',
                title: '比赛',
                content: '用于日常的跳绳比赛'
            },{
                image: 't11.png',
                title: '考试',
                content: '根据初中中考、模考的业务流程，<br/>量身定制的一套支持数据安全性好、<br/>高稳定性、操作简便的跳绳软硬件管理平台'
            }
        ]
    }
]